<template>
  <div class="tab-pane" :id="tabId">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    tabId: {
      required: true,
      type: String,
    }
  }
}
</script>
