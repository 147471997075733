<template>
  <ul class="nav nav-tabs" :class="{'nav-tabs-card': navTabsCard, 'nav-tabs-mb-icon': tabIcons}">
    <slot name="tabs"></slot>
  </ul>
  <div class="tab-content">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    navTabsCard: Boolean,
    tabIcons: Boolean,
  }
}
</script>
