<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-head title="Advisors / ">
            <template v-slot:title_extras>
              <strong class="text-primary small">{{ advisorData.info.first_name+' '+advisorData.info.last_name}}</strong>
            </template>
            <template v-slot:description_extras>
              <list-inline>
                <list-inline-item>Advisor ID: <span class="text-base">{{advisorData.info.id}}</span></list-inline-item>
                <list-inline-item>Last Login: <span class="text-base">{{advisorData.info.last_login.date}}</span></list-inline-item>
              </list-inline>
            </template>
          </block-head>
          <block-content>
            <card no-padding>
              <card-aside sidebar-position="right" :aside-active="asideActive" @toggle-sidebar="asideStatus">
                <template v-slot:contentWrapper>
                  <nav-tabs tab-icons nav-tabs-card>
                    <template v-slot:tabs>
                      <nav-tab-item
                          v-for="(tab,index) in tabs"
                          :tab-id="index"
                          :tab-name="tab.name"
                          :icon="tab.icon"
                          :key="'tb'+index"
                          :current-tab="currentTab"
                          @switch-tab="switchTab"></nav-tab-item>
                    </template>
                    <template v-slot:content>
                      <nav-tab-pane tab-id="p_info" :class="{active: currentTab === 'p_info'}" class="nav-tabs-card">
                        <personal-info :user-data="advisorData.info"></personal-info>
                      </nav-tab-pane>
                      <nav-tab-pane tab-id="leads" :class="{active: currentTab === 'leads'}">
                        <assigned-leads :current-tab="currentTab" :user-id="advisorData.info.id"></assigned-leads>
                      </nav-tab-pane>
                      <nav-tab-pane tab-id="availability" :class="{active: currentTab === 'availability'}"></nav-tab-pane>
                      <nav-tab-pane tab-id="activities" :class="{active: currentTab === 'activities'}" class="nav-tabs-card">
                        <activity-log :current-tab="currentTab" :user-id="advisorData.info.user_id"></activity-log>
                      </nav-tab-pane>
                    </template>
                  </nav-tabs>
                </template>
                <template v-slot:aside>
                  <card-inner-group>
                    <card-inner>
                      <user-card :name="advisorData.info.first_name+' '+advisorData.info.last_name" badge-text="Advisor" :image="advisorData.info.dp" :initials="advisorData.info.initials">
                        <template v-slot:extras>
                          <span class="sub-text">{{advisorData.info.email}}</span>
                        </template>
                      </user-card>
                    </card-inner>
                    <card-inner class="card-inner-sm">
                      <btn-toolbar class="justify-center">
                        <btn-toolbar-item>
                          <nk-button type="" is-link class="btn-trigger" is-icon-only-button="">
                            <nio-icon icon="ni-mail"></nio-icon>
                          </nk-button>
                        </btn-toolbar-item>
                        <btn-toolbar-item>
                          <nk-button type="" is-link class="btn-trigger text-danger" is-icon-only-button="">
                            <nio-icon icon="ni-na"></nio-icon>
                          </nk-button>
                        </btn-toolbar-item>
                      </btn-toolbar>
                    </card-inner>
                    <card-inner>
                      <row class="text-center">
                        <column default="4">
                          <profile-stats label="Total Leads" :value="advisorData.stats.assigned_leads"></profile-stats>
                        </column>
                        <column default="4">
                          <profile-stats label="Returned" :value="advisorData.stats.returned"></profile-stats>
                        </column>
                        <column default="4">
                          <profile-stats label="Appointments" :value="advisorData.stats.appointments"></profile-stats>
                        </column>
                      </row>
                    </card-inner>
                    <card-inner>
                      <h6 class="overline-title-alt mb-2">Additional</h6>
                      <row class="g-3">
                        <column default="6">
                          <span class="sub-text">User ID:</span>
                          <span>{{ advisorData.info.id }}</span>
                        </column>
                        <column default="6">
                          <span class="sub-text">Last Login:</span>
                          <span>{{ advisorData.info.last_login.date }}</span>
                        </column>
                        <column default="6">
                          <span class="sub-text">Account:</span>
                          <span :class="{'text-success' :advisorData.info.user_status === 1, 'text-danger' : advisorData.info.user_status === 0}">
                            {{ advisorData.info.user_status === 1 ? 'Active' : advisorData.info.user_status === 0 ? 'Inactive' : '' }}
                          </span>
                        </column>
                        <column default="6">
                          <span class="sub-text">Register At:</span>
                          <span>{{ advisorData.info.created_at }}</span>
                        </column>
                      </row>
                    </card-inner>
                  </card-inner-group>
                </template>
              </card-aside>
            </card>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, alertController, loadingController, toastController} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import {MainBlock, BlockHead} from "@core/layouts";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardInner, CardInnerGroup} from "@core/components/cards";

import "bootstrap"

import {useRoute, useRouter} from "vue-router"
import {ListInline, ListInlineItem} from "@core/components/list-inline";
import CardAside from "@core/layouts/card-aside/CardAside";
import NavTabs from "@core/components/nav-tabs/NavTabs";
import NavTabItem from "@core/components/nav-tabs/NavTabItem";
import NavTabPane from "@core/components/nav-tabs/NavTabPane";

import UserCard from "@/views/admin/advisors/components/UserCard"
import BtnToolbar from "@core/components/btn-toolbar/BtnToolbar";
import BtnToolbarItem from "@core/components/btn-toolbar/components/Item";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import ProfileStats from "@/views/admin/advisors/components/ProfileStats";
import PersonalInfo from "@/views/admin/advisors/components/PersonalInfo";
import ActivityLog from "@/views/admin/advisors/components/ActivityLog";
import AssignedLeads from "@/views/admin/advisors/components/AssignedLeads";


export default defineComponent({
  components: {
    AssignedLeads,
    ActivityLog,
    PersonalInfo,
    ProfileStats,
    Column,
    Row,
    NkButton,
    NioIcon,
    BtnToolbarItem,
    BtnToolbar,
    CardInner,
    NavTabPane,
    NavTabItem,
    NavTabs,
    CardAside,
    ListInlineItem,
    ListInline,
    Card,
    CardInnerGroup,
    BlockContent,
    MainBlock,
    BlockHead,
    NavBar,
    VerticalNavMenu,
    UserCard,
    IonContent,
    IonPage,
  },
  setup() {

    const router = useRouter()
    const route  = useRoute()

    let assignLead = ref(false)
    assignLead.value = route.params.action && route.params.action === 'assign_lead'

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let advisors     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getAdvisors(true)
    }

    let cancelToken = false
    const getAdvisors = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/advisors/list', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            mandate_filter: mandateFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              advisors.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              advisors.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getAdvisors(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onMounted(()=> getAdvisors())

    watch(selectAll, (n) => {
      for(let x in advisors.value){
        advisors.value[x].selected = n
      }
    })

    const changeAccountStatus = async ( advisors, action ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let alertMessage = ''
      let status = ''
      if(advisors.length > 1){
        alertMessage = `Are you sure to ${action} the ${advisors.length} selected advisors?`
        status = (action === 'suspend') ? 0 : 1
      }else{
        alertMessage = `Are you sure to ${(advisors[0].user_status === 0 ? 'activate' : 'suspend')} ${advisors[0].name}'s account?`
        status = (advisors[0].user_status === 0) ? 1 : 0
      }

      let ids = advisors.reduce( (item) => {
        return item.user_id
      })

      let iAlert = await alertController.create({
        message: alertMessage,
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: () => {
              loading.present()
              axios.post('/api/admin/advisors/account_status', {ids: JSON.stringify(ids), status: status})
              .then( resp => {

                toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                toast.color   = resp.data.success ? "primary" : "danger"
                if(resp.data.success){
                  for(let x in advisors){
                    advisors[x].user_status = advisors[x].user_status === 0 ? 1 : 0
                  }
                }
                toast.present()
              })
              .catch(error => {
                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                toast.color = "danger"
                toast.present()
              })
              .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }
    const deleteAdvisors = async ( _advisors ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let ids = []
      for(let x in _advisors){
        ids.push( _advisors[x].user_id)
      }

      let iAlert = await alertController.create({
        message: `Are you sure to delete the ${_advisors.length} selected advisors?`,
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: () => {
              loading.present()
              axios.post('/api/admin/advisors/delete', {ids: JSON.stringify(ids)})
              .then( resp => {
                toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                toast.color   = resp.data.success ? "primary" : "danger"
                if(resp.data.success){
                  // Remove the deleted entries from the advisors array
                  for(let x in ids){
                    for(let y in advisors.value){
                      if(advisors.value[y].user_id === ids[x]){
                        advisors.value.splice(y,1)
                        break;
                      }
                    }
                  }
                  // All deleted, no entry left in the advisor array? and previous pages exists
                  if( !advisors.value.length && pagination.page > 1 ){
                    // Deduct from total records value
                    pagination.totalRecords -= ids.length
                    // Goto previous page
                    pagination.page--
                    getAdvisors(true)
                  }
                }
                toast.present()
              })
              .catch(error => {
                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                toast.color = "danger"
                toast.present()
              })
              .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedAdvisors = []
      for(let x in advisors.value){
        if( advisors.value[x].selected){
          selectedAdvisors.push( advisors.value[x])
        }
      }
      if( selectedAdvisors.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'email' ){
        // TODO
        return false
      }
      else if( bulkSelectedAction.value === 'suspend' ){
        await changeAccountStatus(selectedAdvisors, 'suspend')
      }
      else{
        // Delete
        await deleteAdvisors(selectedAdvisors)
      }
    }

    // Get advisor profile
    let advisorData = reactive({
      info:{
        address: "",
        afm_number: "",
        ch_com_number: "",
        city: "",
        company: "",
        created_at: "",
        dp: "",
        email: ".",
        first_name: "",
        id: 0,
        initials: '',
        last_login: {date: "", text: ""},
        last_name: "",
        phone_number: "",
        post_code: "",
        province: "",
        updated_at: "",
        user_id: 0,
        user_status: -1,
      },
      stats: {
        assigned_leads: '-',
        returned: '-',
        appointments: '-',
      },
    })
    const getProfile = () => {
      axios.get('/api/admin/advisors/'+route.params.id+'/profile')
          .then( resp => {
            if(resp.data.success){
              for(let x in resp.data.data.info){
                advisorData.info[x] = resp.data.data.info[x]
              }
              for(let x in resp.data.data.stats){
                advisorData.stats[x] = resp.data.data.stats[x]
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    }
    onMounted(() => getProfile())

    // Tabs
    let asideActive = ref(false)
    const asideStatus = () => {
      asideActive.value = !asideActive.value
    }
    let currentTab = ref( (route.params.tab ? route.params.tab : 'p_info') )
    const switchTab = (toTab) => {
      currentTab.value = toTab
      asideActive.value = false
    }

    watch( () => route.params.tab, (n) => {
      currentTab.value = n ? n : 'p_info'
    })

    const tabs = {
      p_info: {
        name: 'Personal',
        icon: 'ni-user-circle',
      },
      leads: {
        name: 'Leads',
        icon: 'ni-coin-alt-fill',
      },
      availability: {
        name: 'Availability',
        icon: 'ni-calendar-check',
      },
      activities: {
        name: 'Activities',
        icon: 'ni-activity',
      }
    }

    return {
      advisorData,
      switchTab,
      asideActive,
      asideStatus,
      currentTab,
      tabs,

      advisors,
      getAdvisors,
      loadingData,
      searchAdvisors,

      mandateFilter,
      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      changeAccountStatus,

      bulkSelectedAction,
      bulkAction,

      assignLead,
      router,
    }
  }
});

</script>
