<template>
  <div class="nk-block-head px-4">
    <h5>Assigned Leads</h5>
  </div>
  <div class="card-inner p-0">
    <special-table>
      <table-row is-heading>
        <table-col has-check-box>
          <b-check-box id="select_all" v-model="selectAll"></b-check-box>
        </table-col>
        <table-col>
          <span class="sub-text">Lead#</span>
        </table-col>
        <table-col>
          <span class="sub-text">Lead</span>
        </table-col>
        <table-col break-point="mb">Phone</table-col>
        <table-col break-point="md">Assign date</table-col>
        <table-col break-point="lg">Opened</table-col>
        <table-col break-point="lg">Current status</table-col>
        <table-col class="nk-tb-col-tools"></table-col>
      </table-row>
      <table-row v-for="lead in assignedLeads.data" :key="'ld'+lead.id">
        <table-col has-check-box>
          <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
        </table-col>
        <table-col>{{ lead.lead_number }}</table-col>
        <table-col>
          <user-small-card
              :user-name="lead.first_name+' '+lead.last_name"
              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
        </table-col>
        <table-col break-point="mb">
          <span class="tb-lead">{{ lead.phone_no }}</span>
        </table-col>
        <table-col break-point="md">{{ lead.assign_date }}</table-col>
        <table-col break-point="lg">{{ lead.opened_time }}</table-col>
        <table-col break-point="lg">{{ lead.status_text }}</table-col>
        <table-col class="nk-tb-col-tools">
          <ul class="nk-tb-actions gx-1">
            <li>
              <drop-down-list>
                <list-item icon="ni-eye" text="View Details" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                <list-item icon="ni-curve-left-down" text="Withdraw" v-on:click="withdrawLeads([lead])"></list-item>
              </drop-down-list>
            </li>
          </ul>
        </table-col>
      </table-row>

    </special-table>

  </div>
  <div class="card-inner" v-if="assignedLeads.total_records > 10">
    <pagination
        v-model="assignedLeads.current_page"
        :records="assignedLeads.total_records"
        :per-page="10"
        @paginate="paginationCallback"
        theme="bootstrap4"
        :options="{chunk: 5, texts:{count: '||'}}"/>
  </div>
</template>

<script>
import Pagination from "v-pagination-3"
import {reactive, watch} from "vue";
import axios from "@/libs/axios";

import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";

import {BCheckBox} from "@core/components/bp-form";

export default {
  components: {
    BCheckBox,
    DropDownList,
    ListItem,
    Pagination,
    SpecialTable,
    TableRow,
    TableCol,
    UserSmallCard,
  },
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    let assignedLeads = reactive({
      data: [],
      total_records: 0,
      current_page: 1,
    })

    // Get activity log
    const getAssignedLeads = (reset) => {

      axios.post('/api/admin/advisors/'+props.userId+'/leads')
          .then( resp => {
            if(resp.data.success){
              if(reset){
                assignedLeads.data = []
              }
              for(let x in resp.data.data){
                assignedLeads.data.push(resp.data.data[x])
              }
              if(assignedLeads.current_page === 1){
                assignedLeads.total_records = resp.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'leads' && !assignedLeads.data.length){
        getAssignedLeads()
      }
    })

    return {
      assignedLeads,
    }

  }
}
</script>
