<template>
  <div class="user-card user-card-s2">
    <div class="user-avatar lg bg-primary">
      <img v-if="image" :src="image" alt="Avatar"/>
      <span v-else class="text-uppercase">{{ initials }}</span>
    </div>
    <div class="user-info">
      <div class="badge badge-outline-light badge-pill ucap" v-if="badgeText">{{badgeText}}</div>
      <h5>{{name}}</h5>
      <slot name="extras" v-if="hasExtras"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    initials: String,
    badgeText: String,
    name: {
      required: true,
      type: String,
    },
  },
  setup( props, {slots}){

    const hasExtras = slots.extras && slots.extras().length
    return {
      hasExtras,
    }
  }
}
</script>
