<template>
  <li class="nav-item">
    <a class="nav-link" :class="{active: (tabId === currentTab)}"  href="javascript:;" v-on:click="switchTab">
      <nio-icon :icon="icon" v-if="icon && icon.length"></nio-icon>
      <span>{{ tabName }}</span>
    </a>
  </li>
</template>

<script>
import NioIcon from "@core/components/nio-icon/NioIcon";
export default {
  components: {NioIcon},
  props: {
    tabId: {
      required: true,
      type: String,
    },
    tabName: {
      required: true,
      type: String,
    },
    icon: String,
    currentTab: String,
  },
  emits: ['switchTab'],
  setup( props, {emit}){
    const switchTab = () => {
      emit('switchTab', props.tabId)
    }
    return {
      switchTab,
    }
  }
}
</script>
