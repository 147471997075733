<template>
  <ul class="list-inline">
    <slot></slot>
  </ul>
</template>

<script>
export default {

}
</script>