<template>
  <div class="nk-block-head">
    <h5>Activity Log</h5>
  </div>
  <table class="table table-ulogs">
    <thead class="thead-light">
    <tr>
      <th class="tb-col-os"><span class="overline-title">Browser <span class="d-sm-none">/ IP</span></span></th>
      <th class="tb-col-ip"><span class="overline-title">IP</span></th>
      <th class="tb-col-time"><span class="overline-title">Time</span></th>
      <th class="tb-col-action"><span class="overline-title">Event</span></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="log in activityLog.data" :key="log.id">
      <td class="tb-col-os">{{ log.user_agent }}</td>
      <td class="tb-col-ip"><span class="sub-text">{{ log.ip }}</span></td>
      <td class="tb-col-time"><span class="sub-text">{{ log.created_at }}</span></td>
      <td class="tb-col-action">{{ log.activity_type }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {reactive, watch} from "vue";
import axios from "@/libs/axios";

export default {
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    let activityLog = reactive({
      data: [],
      total_records: 0,
      current_page: 1,
    })

    // Get activity log
    const getActivityLog = (reset) => {

      axios.post('/api/admin/advisors/'+props.userId+'/activity_log')
          .then( resp => {
            if(resp.data.success){
              if(reset){
                activityLog.data = []
              }
              for(let x in resp.data.data){
                activityLog.data.push(resp.data.data[x])
              }
              if(activityLog.current_page === 1){
                activityLog.total_records = resp.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'activities' && !activityLog.data.length){
        getActivityLog()
      }
    })

    return {
      activityLog,
    }

  }
}
</script>
