<template>
  <div class="custom-control custom-radio">
    <input type="radio" :id="id" :name="name" class="custom-control-input" :value="value" @change="$emit('update:modelValue', $event.target.value)">
    <label class="custom-control-label" :for="id">{{ $t(label) }}</label>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  props: {
    value: null,
    id: {
      type: String,
      default: 'radio',
    },
    name: {
      type: String,
      default: 'radio',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: null,
  },
})
</script>
