<template>
  <div class="profile-ud-item">
    <div class="profile-ud wider">
      <span class="profile-ud-label">{{label}}</span>
      <span class="profile-ud-value">{{value}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
  }
}
</script>
