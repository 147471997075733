<template>
  <div class="nk-block">
    <div class="nk-block-head">
      <h5>Personal Information</h5>
      <p>Basic info, like name and address, that advisor has shared with us.</p>
    </div>
    <profile-ud-list>
      <profile-ud-item label="First Name" :value="userData.first_name"></profile-ud-item>
      <profile-ud-item label="Last Name" :value="userData.last_name"></profile-ud-item>
      <profile-ud-item label="Company" :value="userData.company"></profile-ud-item>
      <profile-ud-item label="Chamber of commerce no." :value="userData.ch_com_number"></profile-ud-item>
      <profile-ud-item label="AFM no." :value="userData.afm_number"></profile-ud-item>
    </profile-ud-list>
  </div>
  <div class="nk-block">
    <div class="nk-block-head nk-block-head-line">
      <h6 class="title overline-title text-base">Contact Information</h6>
    </div>
    <profile-ud-list>
      <profile-ud-item label="Phone No." :value="userData.phone_number"></profile-ud-item>
      <profile-ud-item label="Post Code" :value="userData.post_code"></profile-ud-item>
      <profile-ud-item label="City" :value="userData.city"></profile-ud-item>
      <profile-ud-item label="Province" :value="userData.province"></profile-ud-item>
    </profile-ud-list>
  </div>
</template>

<script>
import ProfileUdList from "@/views/admin/advisors/components/ProfileUdList";
import ProfileUdItem from "@/views/admin/advisors/components/ProfileUdItem";
export default {
  components: { ProfileUdItem, ProfileUdList},
  props: {
    userData:{
      required: true,
      type: Object,
    }
  }
}
</script>
